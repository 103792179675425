import * as React from 'react'

// Types
import { IconSize } from '@/lib/enums'

function Dribbble({
  color = 'currentColor',
  size = IconSize.Large,
  className
}: IconProps) {
  return (
    <svg
      className={className}
      fill="none"
      height={size}
      viewBox="0 0 24 24"
      width={size}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        clipRule="evenodd"
        d="M3 12L3.00014 11.9485C3.49567 11.9827 3.99581 12 4.50001 12C7.15526 12 9.69809 11.5187 12.046 10.6385C12.2534 11.0683 12.4533 11.5023 12.6456 11.9404C9.507 12.9666 6.85671 15.072 5.13325 17.8182C3.80259 16.2493 3 14.2184 3 12ZM13.4053 13.7977C10.5267 14.7003 8.11507 16.6583 6.62372 19.2184C8.12383 20.3375 9.98455 21 12 21C13.1559 21 14.2608 20.7821 15.2761 20.3852C14.8386 18.1152 14.2091 15.9135 13.4053 13.7977ZM17.1194 19.4031C16.6878 17.3281 16.1031 15.3092 15.3776 13.3587C15.9082 13.287 16.4498 13.25 17 13.25C18.3304 13.25 19.6103 13.4665 20.8063 13.8662C20.3244 16.1516 18.9753 18.1173 17.1194 19.4031ZM14.6145 11.4524C14.3826 10.9119 14.1396 10.3771 13.8859 9.84855C15.7648 8.93541 17.4907 7.75665 19.0149 6.36111C20.2236 7.86281 20.9599 9.76073 20.9984 11.8293C19.7315 11.4524 18.3894 11.25 17 11.25C16.1868 11.25 15.3898 11.3193 14.6145 11.4524ZM12.9772 8.06593C14.6663 7.24928 16.2197 6.19618 17.5945 4.9496C16.0584 3.7291 14.1144 3 12 3C11.2872 3 10.5937 3.08287 9.92875 3.23949C11.0496 4.77276 12.0691 6.38487 12.9772 8.06593ZM7.96687 3.95207C5.60859 5.1362 3.84268 7.3264 3.23231 9.95947C3.65141 9.98636 4.07412 10 4.50001 10C6.82572 10 9.05633 9.59287 11.1245 8.84596C10.1894 7.13498 9.13306 5.49983 7.96687 3.95207ZM12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1Z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  )
}

export default React.memo(Dribbble)
